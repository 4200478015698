import { Box, Button, IconButton, Typography } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import PopupForm from '../common/PopupForm';
import { toast } from 'react-toastify';
import amendmentTypes, {
    amendmentTypesById,
} from '../../constants/amendmentTypes';
import {
    getAdditionalSigners,
    getAmendmentTypes,
    getAvailableDepositSchedules,
    getAvailableIncentives,
    getDocupilotTemplates,
    getExistingDeposits,
    getExistingIncentives,
    getManualPaymentOptions,
    getTitles,
    getUpgradeOptionsByUpgrade,
    getUpgradeOptionsWithProperty,
    getUpgradeOptionsWithoutProperty,
} from '../../scripts/cms';
import DynamicIcon from '../common/DynamicIcon';
import { occupancyDeclarations } from '../../constants/occupancyDeclarations';
import { DataGrid } from '@mui/x-data-grid';
import { getDate, getRow } from '../../scripts/table';
import DepositSchedulePreview from '../administration/DepositSchedulePreview';

function Amendments({ purchase, auction, project, refresh }) {
    const [showAmendmentTypeForm, setShowAmendmentTypeForm] = useState(false);
    const [amendmentTypeId, setAmendmentTypeId] = useState();
    const [showForm, setShowForm] = useState(false);
    const [defaults, setDefaults] = useState();

    const [showCancel, setShowCancel] = useState(false);
    const [current, setCurrent] = useState();

    const [error, setError] = useState();

    // Update the default form for the amendment creation (needed here because of re-rendering state issues)
    useEffect(() => {
        if (purchase && amendmentTypeId) {
            setDefaults({
                amendmentTypeId,
                projectId: project.id,
                purchaseId: purchase.id,
                propertyId: auction.propertyId,
                auctionId: auction.id,
                serviceFee:
                    project.config.amendment?.serviceFees?.[amendmentTypeId] ||
                    0,
            });
        }
    }, [purchase, amendmentTypeId]);

    function handleSubmit(form) {
        setError();

        axios
            .post(`/amendment/create`, form)
            .then((res) => {
                setShowAmendmentTypeForm(false);
                setAmendmentTypeId(null);
                setShowForm(false);
                refresh();
            })
            .catch(function (err) {
                setError({
                    server:
                        err.response.data?.message || err.response?.statusText,
                });
            });
    }

    function handleChooseAmendmentType(form) {
        if (form.amendmentTypeId) {
            setShowAmendmentTypeForm(false);
            setAmendmentTypeId(form.amendmentTypeId);
            setShowForm(true);
        }
    }

    function handleCancel() {
        setError();

        axios
            .post(`/amendment/cancel/${current.id}`)
            .then((res) => {
                setShowCancel(false);
                setCurrent(null);
                refresh();
            })
            .catch(function (err) {
                setError({
                    server:
                        err.response.data?.message || err.response?.statusText,
                });
            });
    }

    if (!purchase || !purchase.envelope?.signDate) {
        return null;
    }

    return (
        <Box marginTop={2}>
            <Typography fontSize={18} fontWeight="medium" gutterBottom>
                Amendments
            </Typography>

            {purchase.amendments.length > 0 && (
                <DataGrid
                    rows={purchase.amendments.map((amendment) => {
                        return {
                            id: amendment.id,
                            amendmentType: amendment.amendmentType.title,
                            amendmentTypeId: amendment.amendmentTypeId,
                            envelopeId: amendment.envelopeId,
                            priceAdjustment: amendment.priceAdjustment,
                            serviceFee: amendment.serviceFee,
                            finished: amendment.finished,
                            sendDate: getDate(
                                amendment.envelope?.sendDate,
                                true
                            ),
                            signDate: getDate(
                                amendment.envelope?.signDate,
                                true
                            ),
                            cancellationDate: getDate(
                                amendment.envelope?.cancellationDate,
                                true
                            ),
                        };
                    })}
                    columns={[
                        {
                            field: 'id',
                            headerName: 'ID',
                            width: 50,
                            headerAlign: 'center',
                            align: 'center',
                        },
                        {
                            field: 'amendmentTypeId',
                            headerName: 'Type',
                            flex: 1,
                            headerAlign: 'center',
                            align: 'center',
                            renderCell: (params) => {
                                return params.row.amendmentType;
                            },
                        },
                        {
                            field: 'envelopeId',
                            headerName: 'Envelope ID',
                            width: 300,
                            headerAlign: 'center',
                            align: 'center',
                        },
                        {
                            field: 'priceAdjustment',
                            headerName: 'Price Adjustment',
                            width: 150,
                            headerAlign: 'center',
                            align: 'center',
                            renderCell: (params) => {
                                return `$${params.row.priceAdjustment.toLocaleString()}`;
                            },
                        },
                        {
                            field: 'serviceFee',
                            headerName: 'Service Fee',
                            width: 150,
                            headerAlign: 'center',
                            align: 'center',
                            renderCell: (params) => {
                                return `$${params.row.serviceFee.toLocaleString()}`;
                            },
                        },
                        {
                            field: 'finished',
                            headerName: 'Status',
                            width: 90,
                            headerAlign: 'center',
                            align: 'center',
                            renderCell: (params) => {
                                return params.row.finished ? (
                                    params.row.cancellationDate ? (
                                        <DynamicIcon
                                            icon="Close"
                                            colour="#ee3333"
                                        />
                                    ) : (
                                        <DynamicIcon
                                            icon="Check"
                                            colour="green"
                                        />
                                    )
                                ) : (
                                    <DynamicIcon
                                        icon="Assignment"
                                        colour="#166cdb"
                                    />
                                );
                            },
                        },
                        {
                            field: 'signDate',
                            headerName: 'Signed',
                            width: 150,
                            headerAlign: 'center',
                            align: 'center',
                        },
                        {
                            field: 'cancellationDate',
                            headerName: 'Cancelled',
                            width: 150,
                            headerAlign: 'center',
                            align: 'center',
                        },
                        {
                            field: 'sendDate',
                            headerName: 'Sent',
                            width: 150,
                            headerAlign: 'center',
                            align: 'center',
                        },
                        {
                            field: 'cancel',
                            headerName: 'Cancel',
                            width: 90,
                            headerAlign: 'center',
                            align: 'center',
                            renderCell: (params) => {
                                const restricted =
                                    params.row.finished ||
                                    !params.row.envelopeId;

                                const onClick = (e) => {
                                    setCurrent(getRow(e, params));
                                    setShowCancel(true);
                                };

                                return (
                                    <IconButton
                                        onClick={onClick}
                                        disabled={restricted}
                                    >
                                        <DynamicIcon
                                            icon="Cancel"
                                            colour={
                                                restricted ? '#999' : '#ee3333'
                                            }
                                        />
                                    </IconButton>
                                );
                            },
                            sortable: false,
                        },
                    ]}
                    pageSize={purchase.amendments.length}
                    rowsPerPageOptions={[purchase.amendments.length]}
                    disableSelectionOnClick
                    autoHeight
                    sx={{ backgroundColor: 'white', marginBottom: 1 }}
                />
            )}

            <Button
                variant="dark"
                size="tiny"
                onClick={() => {
                    setShowAmendmentTypeForm(true);
                }}
                sx={{ marginBottom: 1 }}
                disabled={purchase.activeAmendment || !auction.sold}
            >
                Add
            </Button>

            <PopupForm
                title={`Choose Amendment Type`}
                yes="Create"
                show={showAmendmentTypeForm}
                setShow={setShowAmendmentTypeForm}
                action={handleChooseAmendmentType}
                inputs={[
                    [
                        {
                            id: 'amendmentTypeId',
                            source: getAmendmentTypes,
                            label: 'Amendment Type',
                        },
                    ],
                ]}
            />
            <PopupForm
                width={800}
                title={amendmentTypesById[amendmentTypeId]}
                yes="Create Amendment"
                show={showForm}
                setShow={setShowForm}
                action={handleSubmit}
                inputs={getAmendmentInputs(amendmentTypeId)}
                defaults={defaults}
                errors={error}
                setErrors={setError}
            />
            <PopupForm
                title="Cancel Amendment?"
                yes="Submit"
                show={showCancel}
                setShow={setShowCancel}
                action={handleCancel}
                body="Are you sure you want to cancel this amendment"
                errors={error}
                setErrors={setError}
            />
        </Box>
    );
}

function getAmendmentInputs(amendmentTypeId) {
    const baseInputs = [
        [
            {
                id: 'docupilotTemplateId',
                source: getDocupilotTemplates,
                sourceParameters: [
                    { id: 'amendmentTypeId' },
                    { id: 'projectId' },
                    { id: 'docupilotTemplateTypeId', value: 3 },
                ],
                optional: true,
                placeholder: 'None',
            },
            {
                id: 'serviceFee',
                number: true,
            },
            {
                id: 'skipAgreement',
                description: `Using this field means that you want to skip the DocuSign process for this amendment. Upon submitting this form, the action will be taken IMMEDIATELY without the guaranteed authorization from the purchasers and developer.\nIf you want to skip the DocuSign, enter SKIP in this field.`,
                optional: true,
            },
        ],
    ];

    switch (+amendmentTypeId) {
        // Add Purchaser
        case amendmentTypes.ADD_PURCHASER.id:
            return [
                ...baseInputs,
                [
                    {
                        id: 'officer',
                        toggle: {
                            toggleOfficer: {
                                id: 'toggleOfficer',
                                inputs: [
                                    [
                                        {
                                            id: 'position',
                                        },
                                    ],
                                ],
                                label: 'Officer',
                            },
                            togglePurchaser: {
                                id: 'togglePurchaser',
                                label: 'Purchaser',
                                inputs: [],
                            },
                        },
                    },
                ],
                [
                    {
                        id: 'title',
                        children: getTitles(),
                    },
                    { id: 'email' },
                ],
                [
                    { id: 'firstName' },
                    { id: 'middleName', optional: true },
                    { id: 'lastName' },
                ],
            ];
        // Remove Purchaser
        case amendmentTypes.REMOVE_PURCHASER.id:
            return [
                ...baseInputs,
                [
                    {
                        id: 'signatureId',
                        label: 'Purchaser',
                        source: getAdditionalSigners,
                        sourceParameters: [{ id: 'purchaseId' }],
                        table: {
                            columns: [
                                { field: 'id', headerName: 'ID', flex: 0.5 },
                                {
                                    field: 'firstName',
                                    headerName: 'First',
                                },
                                {
                                    field: 'middleName',
                                    headerName: 'Middle',
                                },
                                {
                                    field: 'lastName',
                                    headerName: 'Last',
                                },
                                {
                                    field: 'email',
                                    headerName: 'Email',
                                },
                            ],
                        },
                    },
                ],
            ];
        // Change Occupancy Declaration
        case amendmentTypes.CHANGE_OCCUPANCY_DECLARATION.id:
            return [
                ...baseInputs,
                [
                    {
                        id: 'occupancyDeclaration',
                        children: occupancyDeclarations,
                    },
                ],
            ];
        // Add Incentive
        case amendmentTypes.ADD_INCENTIVE.id:
            return [
                ...baseInputs,
                [
                    {
                        id: 'type',
                        toggle: {
                            toggleAdd: {
                                id: 'toggleAdd',
                                label: 'Add',
                                inputs: [
                                    [
                                        {
                                            id: 'addIncentive',
                                            source: getAvailableIncentives,
                                            sourceParameters: [
                                                { id: 'projectId' },
                                                { id: 'purchaseId' },
                                            ],
                                            label: 'Add Incentive',
                                            table: {
                                                columns: [
                                                    {
                                                        field: 'templateName',
                                                        headerName: 'Template',
                                                        flex: 2,
                                                    },
                                                    {
                                                        field: 'title',
                                                        headerName: 'Title',
                                                        flex: 2,
                                                    },
                                                    {
                                                        field: 'scheduleIdentifier',
                                                        headerName:
                                                            'Schedule Identifier',
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                ],
                            },
                            toggleReplace: {
                                id: 'toggleReplace',
                                inputs: [
                                    [
                                        {
                                            id: 'removeIncentive',
                                            source: getExistingIncentives,
                                            sourceParameters: [
                                                { id: 'purchaseId' },
                                            ],
                                            label: 'Remove Incentive',
                                            table: {
                                                columns: [
                                                    {
                                                        field: 'templateName',
                                                        headerName: 'Template',
                                                        flex: 2,
                                                    },
                                                    {
                                                        field: 'title',
                                                        headerName: 'Title',
                                                        flex: 2,
                                                    },
                                                    {
                                                        field: 'scheduleIdentifier',
                                                        headerName:
                                                            'Schedule Identifier',
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                    [
                                        {
                                            id: 'addIncentive',
                                            source: getAvailableIncentives,
                                            sourceParameters: [
                                                { id: 'projectId' },
                                                { id: 'purchaseId' },
                                            ],
                                            label: 'Add Incentive',
                                            table: {
                                                columns: [
                                                    {
                                                        field: 'templateName',
                                                        headerName: 'Template',
                                                        flex: 2,
                                                    },
                                                    {
                                                        field: 'title',
                                                        headerName: 'Title',
                                                        flex: 2,
                                                    },
                                                    {
                                                        field: 'scheduleIdentifier',
                                                        headerName:
                                                            'Schedule Identifier',
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                ],
                                label: 'Replace',
                            },
                        },
                    },
                ],
            ];
        // Change Deposit Schedule
        case amendmentTypes.CHANGE_DEPOSIT_SCHEDULE.id:
            return [
                ...baseInputs,
                [
                    {
                        id: 'type',
                        toggle: {
                            toggleExisting: {
                                id: 'toggleExisting',
                                inputs: [
                                    [
                                        {
                                            id: 'depositScheduleId',
                                            source: getAvailableDepositSchedules,
                                            sourceParameters: [
                                                { id: 'projectId' },
                                                { id: 'auctionId' },
                                            ],
                                            label: 'Deposit Schedule',
                                            table: {
                                                columns: [
                                                    {
                                                        field: 'id',
                                                        headerName: 'ID',
                                                        flex: 0.5,
                                                    },
                                                    {
                                                        field: 'title',
                                                        headerName: 'Title',
                                                        flex: 2,
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                ],
                                label: 'Existing',
                            },
                            toggleCustom: {
                                id: 'toggleCustom',
                                inputs: [
                                    [
                                        {
                                            id: 'title',
                                            label: 'Deposit Schedule Name',
                                        },
                                        {
                                            id: 'coolingPeriod',
                                            number: true,
                                            label: 'Cooling period (days)',
                                            disabled: true,
                                        },
                                    ],
                                    [
                                        {
                                            id: 'deposits',
                                            source: getExistingDeposits,
                                            sourceParameters: [
                                                { id: 'purchaseId' },
                                            ],
                                            sourceLoad: true,
                                            disableExpiration: true,
                                            inputs: [
                                                [
                                                    {
                                                        id: 'percent',
                                                        number: true,
                                                        optional: true,
                                                        label: 'Percentage (%)',
                                                        step: 0.001,
                                                    },
                                                    {
                                                        id: 'fixedAmount',
                                                        number: true,
                                                        optional: true,
                                                        label: 'Fixed Amount ($)',
                                                        step: 0.01,
                                                    },
                                                    {
                                                        id: 'subtraction',
                                                        number: true,
                                                        label: 'Amount to deduct',
                                                        description:
                                                            'For usage on % deposits to subtract the fixed amount defined in the first deposit (ex. 5% less $5000)',
                                                        step: 0.01,
                                                    },
                                                    {
                                                        id: 'scheduleFromFirm',
                                                        checkbox: true,
                                                        description:
                                                            'Whether the deposit should be scheduled from the firm date, or from when the APS was signed. First deposit should schedule from firm.',
                                                    },
                                                ],
                                                [
                                                    { id: 'dueString' },
                                                    {
                                                        id: 'dueDays',
                                                        number: true,
                                                        optional: true,
                                                    },
                                                ],
                                            ],
                                            title: 'Deposit',
                                        },
                                    ],
                                    [{ Component: DepositSchedulePreview }],
                                ],
                                label: 'Custom',
                            },
                        },
                    },
                ],
            ];
        // Extend Cooling
        case amendmentTypes.EXTEND_COOLING.id:
            return [...baseInputs, [{ id: 'firmDate', date: true }]];
        // Custom
        case amendmentTypes.CUSTOM.id:
            return [
                ...baseInputs,
                [{ id: 'insert', rows: 6 }],
                [{ id: 'delete', rows: 6 }],
            ];
        // Mutual Release
        case amendmentTypes.MUTUAL_RELEASE.id:
            return [...baseInputs];
        // Add Upgrade
        case amendmentTypes.ADD_UPGRADE.id:
            const upgradeOptionColumns = [
                {
                    field: 'upgrade.title',
                    headerName: 'Upgrade',
                    flex: 2,
                },
                {
                    field: 'title',
                    headerName: 'Title',
                    flex: 2,
                },
                {
                    field: 'upgradeSubtype.title',
                    headerName: 'Subtype',
                    flex: 1.5,
                },
                {
                    field: 'remaining',
                    headerName: 'Remaining',
                    flex: 0.75,
                },
                {
                    field: 'price',
                    headerName: 'Price',
                    Component: (props) => (
                        <div>${props.price.toLocaleString()}</div>
                    ),
                    props: ['price'],
                    flex: 0.75,
                },
            ];

            return [
                ...baseInputs,
                [
                    {
                        id: 'type',
                        toggle: {
                            toggleReplace: {
                                id: 'toggleReplace',
                                inputs: [
                                    [
                                        {
                                            id: 'removeUpgrade',
                                            source: getUpgradeOptionsWithProperty,
                                            sourceParameters: [
                                                { id: 'projectId' },
                                                { id: 'propertyId' },
                                            ],
                                            label: 'Remove Upgrade',
                                            table: {
                                                columns: upgradeOptionColumns,
                                            },
                                        },
                                    ],
                                    [
                                        {
                                            id: 'replacementUpgrade',
                                            source: getUpgradeOptionsByUpgrade,
                                            sourceParameters: [
                                                { id: 'projectId' },
                                                { id: 'removeUpgrade' },
                                                { id: 'propertyId' },
                                            ],
                                            label: 'Add Upgrade',
                                            table: {
                                                columns: upgradeOptionColumns,
                                            },
                                        },
                                    ],
                                ],
                                label: 'Replace',
                            },
                            toggleAdd: {
                                id: 'toggleAdd',
                                label: 'Add',
                                inputs: [
                                    [
                                        {
                                            id: 'addUpgrade',
                                            source: getUpgradeOptionsWithoutProperty,
                                            sourceParameters: [
                                                { id: 'projectId' },
                                                { id: 'propertyId' },
                                            ],
                                            label: 'Add Upgrade',
                                            table: {
                                                columns: upgradeOptionColumns,
                                            },
                                        },
                                    ],
                                ],
                            },
                        },
                    },
                ],
            ];

        // Convert to PAD
        case amendmentTypes.PAD.id:
            return [...baseInputs];

        // Convert to Manual
        case amendmentTypes.MANUAL.id:
            return [
                ...baseInputs,
                [
                    {
                        id: 'manualPaymentOptionId',
                        source: getManualPaymentOptions,
                        label: 'Manual Payment Option',
                        table: { column: 'title' },
                    },
                ],
            ];

        // Inputs not ready
        default:
            return [];
    }
}

export default Amendments;
